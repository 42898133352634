import * as React from "react";
import { getAllProjects } from "../../api/project";
import Layout from "../components/templates/Layout";

const ProjectsPage = () => {
   const [projects, setProjects] = React.useState(getAllProjects);

   return (
      <Layout title="Projects">
         <div className="">
            <div className="">
               <h2 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">My applications</h2>
               <div className="mt-4">
                  <ul class="space-y-4">
                     {projects &&
                        projects.map((project, index) => {
                           return (
                              <li key={`project-${index}`}>
                                 <a href={project.url || undefined} target="_blank" className="block rounded-lg bg-white shadow-md hover:bg-gray-50 focus:outline-none focus:bg-gray-50 dark:bg-gray-900 dark:hover:bg-gray-800 dark:focus:bg-gray-800 dark:text-white transition duration-150 ease-in-out">
                                    <div className="flex items-center px-4 py-4 sm:px-6">
                                       <div className="min-w-0 flex-1 flex items-center">
                                          <div className="flex-shrink-0 h-12 w-12 rounded-full bg-gray-100 overflow-hidden">{project.image && <img src={project.image} alt="KLM Boeing 747" className="w-full h-full object-cover" />}</div>
                                          <div className="min-w-0 flex-1 items-center px-4 md:grid md:grid-cols-2 md:gap-4">
                                             <div>
                                                <div className="text-sm leading-5 font-medium text-green-600 dark:text-green-400 truncate">{project.title}</div>

                                                {project.url && (
                                                   <div className="mt-1 flex items-center text-sm leading-5 text-gray-500 dark:text-gray-400">
                                                      <svg className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                         <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                                                      </svg>
                                                      <span className="truncate">{project.url}</span>
                                                   </div>
                                                )}
                                             </div>
                                             <div className="hidden md:block">
                                                <div>
                                                   <div className="text-sm leading-5 text-gray-900 dark:text-gray-300">Version {project.version}</div>
                                                   <div className="mt-2 flex items-center text-sm leading-5 text-gray-500 dark:text-gray-400">
                                                      {project.status === "In development" ? (
                                                         <svg className="flex-shrink-0 mr-1.5 h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                            <path fill="currentColor" d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"></path>
                                                         </svg>
                                                      ) : (
                                                         <svg className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                                         </svg>
                                                      )}
                                                      {project.status}
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div>
                                          {/* Heroicon name: chevron-right */}
                                          <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                             <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                          </svg>
                                       </div>
                                    </div>
                                 </a>
                              </li>
                           );
                        })}
                  </ul>
               </div>
            </div>
         </div>
      </Layout>
   );
};

export default ProjectsPage;
