export const getAllProjects = [
   {
      title: "Aviationtag last uploaded images",
      url: "https://getatupload.netlify.app/",
      version: "1.1.1",
      status: "Ready",
      image: "https://images.unsplash.com/photo-1584875754507-70ef869c3b21?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80",
   },
   {
      title: "MyFood",
      url: "",
      version: "0.0.1",
      status: "In development",
   },
];
